/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Bucarest"), React.createElement("p", null, "Pour se loger, Bucarest s’articule en quatre quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ro/bucharest/oldtownbucharest.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Lipscani"), " : animées, les rues piétonnes de la vieille ville comptent de nombreuses terrasses."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ro/bucharest/victorieiavenue.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Avenue de la Victoire"), ", au nord : chic et calme, un quartier aux allures de Petit Paris jusqu’au parc Cișmigiu."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ro/bucharest/university-romana.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Place de l’Université"), ", au nord-est : quartier étudiant et centre névralgique de Bucarest."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ro/bucharest/unirii.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Place de l’Union"), ", au sud : près du Palais du Parlement, un quartier résidentiel hérité de Ceausescu."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
